/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
import axios from '@axios'

export const LOCATIONS_APP_STORE_MODULE_NAME = 'app-locations'
export default {
  namespaced: true,
  state: {
    locations: [],
    groups: [],
    display: [],
    displayAll: [],
    tree: [],
  },
  getters: {
    locations(state) {
      return state.locations
    },
    groups(state) {
      return state.groups
    },
    display(state) {
      return state.display
    },
    tree(state) {
      return state.tree
    },
    displayAll(state) {
      return state.displayAll
    },
  },
  mutations: {
    SET_LOCATIONS(state, value) {
      state.locations = value
      state.tree = [...value, ...state.display]
    },
    SET_GROUPS(state, val) {
      state.groups = val
    },
    SET_TREE(state, val) {
      state.tree = val
    },
    SET_DISPLAY(state, value) {
      state.display = value
      state.tree = [...state.locations, ...value]
    },
    SET_DISPLAY_ALL(state, value) {
      state.displayAll = value
    },
  },
  actions: {
    fetchLocations(ctx, scroll = false) {
      axios
        .get('/location')
        .then(async response => {
          const { data } = response.data
          let openedLocations = {}
          try {
            const openItems = localStorage.getItem('location-list-open-locations')
            openedLocations = openItems ? JSON.parse(openItems) : {}
          } catch (e) {
            // no error here
          }
          if (typeof openedLocations !== 'object') {
            openedLocations = {}
          }
          this.dispatch('app-locations/fetchDisplays')
          function foldLocations(locs, allOpenLocations, self) {
            locs.map(el => {
              el.$folded = !(allOpenLocations[el.id] && Array.isArray(allOpenLocations[el.id].path))
              el.$branchClass = `location-branch location-${el.id}`
              el.$nodeBackClass = `L${el.id}`
              if (el.children.length > 0) {
                if (el.displays_number) {
                  el.$childrenClass = 'has-displays'
                }
                foldLocations(el.children, allOpenLocations, self)
              } else if (el.displays_number) {
                el.$childrenClass = 'has-displays'
              } else {
                el.$childrenClass = 'no-displays'
              }

              return el
            })
          }
          foldLocations(data, openedLocations, this)
          await this.commit('app-locations/SET_LOCATIONS', data)
          if (scroll) {
            const locationIds = document.querySelectorAll('.location-branch')
            locationIds[locationIds.length - 1].className += 'pulse'
            locationIds[locationIds.length - 1].scrollIntoView({
              behavior: 'auto',
              block: 'center',
              inline: 'center',
            })
            setTimeout(
              () => {
                locationIds[locationIds.length - 1].classList.remove('pulse')
              }, 1000,
            )
          }
          const self = this
          setTimeout(() => {
            self.dispatch('app-locations/getDisplaysOfOpenLocation')
          }, 100)
        })
        .catch(error => console.log(error))
    },
    fetchDisplaysOfLocation(ctx, { id, path, isUpdate = false }) {
      const [loadingEl] = document.getElementsByClassName(`location-${id}`)
      if (loadingEl) {
        loadingEl.classList.add('loading')
      }
      axios.get(`locations/${id}/display`).then(response => {
        let ref = ctx.state.tree
        for (const index in path) {
          const i = path[index]
          ref = ref[i]
          if (path.length === 1 || index == path.length - 1) {
            break
          } else {
            ref = ref.children
          }
        }
        if (isUpdate) {
          ref.children = ref.children.filter(children => typeof children.id === 'number')
        }
        const { data } = response.data
        data.map(d => {
          d.$childrenClass = `page-parent level-${path.length + 1}`
          d.$branchClass = 'display-branch'
          const left = 346 + (30 * path.length)
          d.$childrenStyle = `left: ${left}px; width: calc(100% - ${left + 30}px)`

          if (d.children && Array.isArray(d.children)) {
            d.children = d.children.map(p => {
              p.$droppable = false
              p.$nodeClass = 'page-branch'
              if (d.mirror_id) {
                p.$nodeClass = 'page-branch mirrored'
              }

              return p
            })
          }
          d.children.push({ $hidden: true })

          return d
        })
        data.forEach(el => {
          let addDisplay = true
          ref.children.forEach(children => {
            if (`${el.id}display` === children.id) {
              addDisplay = false
            }
          })
          if (addDisplay) {
            if (el.children && Array.isArray(el.children)) {
              el.children = el.children.map(p => {
                p.$droppable = false
                p.$nodeClass = 'page-branch'
                if (el.mirror_id) {
                  p.$nodeClass = 'page-branch mirrored'
                }

                return p
              })
            }
            if (ref.type == 'location') {
              const left = 346 + (30 * path.length)
              ref.children.push({
                id: `${el.id}display`,
                name: el.name,
                description: el.description,
                type: 'display',
                location_id: el.location_id,
                screenshot: el.screenshot,
                status: el.status,
                mirror_id: el.mirror_id,
                children: el.children,
                manage: el.manage,
                token: el.token,
                $childrenClass: `page-parent level-${path.length + 1}`,
                $childrenStyle: `left: ${left}px; width: calc(100% - ${left + 30}px)`,
                $branchClass: 'display-branch',
                $nodeBackClass: `D${el.id}`,
              })
            }
          }
        })
      }).finally(() => {
        if (loadingEl) {
          loadingEl.classList.remove('loading')
        }
      })
    },
    fetchDisplaysAll() {
      axios
        .get('/displays')
        .then(async response => {
          const { data } = response.data
          this.commit('app-locations/SET_DISPLAY_ALL', data)
        })
        .catch(error => console.log(error))
    },
    fetchDisplays(ctx, scroll = false) {
      this.dispatch('app-locations/fetchDisplaysAll')
      axios
        .get('/locations/-1/display')
        .then(async response => {
          const { data } = response.data
          const display = data.map(d => {
            d.$childrenClass = 'page-parent level-1'
            d.$childrenStyle = 'left: 346px; width: calc(100% - 376px)'
            d.$branchClass = 'display-branch'
            d.$nodeBackClass = `D${d.id}`
            if (d.children && Array.isArray(d.children)) {
              d.children = d.children.map(p => {
                p.$droppable = false
                p.$nodeClass = 'page-branch'
                if (d.mirror_id) {
                  p.$nodeClass = 'page-branch mirrored'
                }

                return p
              })
            }
            d.children.push({ $hidden: true })

            return d
          })
          await this.commit('app-locations/SET_DISPLAY', display)
          if (scroll) {
            window.scrollTo(0, document.body.scrollHeight)
            const someElementsItems = document.querySelectorAll('.display-branch')
            someElementsItems[someElementsItems.length - 1].className += 'pulse'
            setTimeout(
              () => {
                someElementsItems[someElementsItems.length - 1].classList.remove('pulse')
              }, 1000,
            )
          }
        })
        .catch(error => console.log(error))
    },

    fetchGroups(ctx, queryParams) {
      axios.get('/groups', { params: queryParams }).then(response => {
        const data = []
        response.data.data.forEach(el => {
          data.push({
            id: el.id,
            name: el.name,
            slug: el.slug,
            description: el.description,
          })
        })
        this.commit('app-locations/SET_GROUPS', data)
      })
    },
    async addLocation(ctx, payload) {
      axios.post('/location', { ...payload }).then(() => {
        this.dispatch('app-locations/fetchLocations', true)
      })
    },
    async addDisplay(ctx, payload) {
      axios.post('/displays', { ...payload }).then(() => {
        this.dispatch('app-locations/fetchDisplays', true)
      })
    },
    duplicateDisplay(ctx, payload) {
      axios.post('/displays', { ...payload }).then(response => {
        const { data } = response.data
        payload.pages.forEach(page => {
          page.display_id = data.id
          this.dispatch('app-locations/addPage', page)
        })
        this.dispatch('app-locations/fetchLocations')
      })
    },
    disconnectDisplay(ctx, token) {
      axios.post(`/displays/${token}/disconnect`, {
        bue_ds_screen_token: token,
      }).then(() => {
        this.dispatch('app-locations/fetchLocations')
      })
    },
    addPage(ctx, payload) {
      const { path } = payload
      axios.post('/pages', { ...payload }).then(() => {
        let ref = ctx.state.tree
        for (const index in path) {
          const i = path[index]
          ref = ref[i]
          if (path.length === 1 || index == path.length - 2) {
            break
          } else {
            ref = ref.children
          }
        }
        if (path.length == 1) {
          this.dispatch('app-locations/fetchDisplays')
        } else {
          path.splice(-1)
          this.dispatch('app-locations/fetchDisplaysOfLocation', { id: ref.id, path, isUpdate: true })
        }
      })
    },
    updatePage(ctx, payload) {
      const { path } = payload
      axios.put(`/pages/${payload.id}`, { name: payload.name, status: payload.status }).then(() => {
        let ref = ctx.state.tree
        path.splice(-1)
        for (const index in path) {
          const i = path[index]
          ref = ref[i]
          if (path.length === 1 || index == path.length - 2) {
            break
          } else {
            ref = ref.children
          }
        }
        if (path.length == 1) {
          this.dispatch('app-locations/fetchDisplays')
        } else {
          path.splice(-1)
          this.dispatch('app-locations/fetchDisplaysOfLocation', { id: ref.id, path, isUpdate: true })
        }
      })
    },
    deletePage(ctx, payload) {
      const { id } = payload
      const { path } = payload
      axios.delete(`/pages/${id}`).then(() => {
        let ref = ctx.state.tree
        path.splice(-1)
        for (const index in path) {
          const i = path[index]
          ref = ref[i]
          if (path.length === 1 || index == path.length - 2) {
            break
          } else {
            ref = ref.children
          }
        }
        if (path.length === 1) {
          this.dispatch('app-locations/fetchDisplays')
        } else {
          path.splice(-1)
          this.dispatch('app-locations/fetchDisplaysOfLocation', { id: ref.id, path, isUpdate: true })
        }
      })
    },
    parentUpdateLocation(ctx, { id, parent_id }) {
      axios
        .put(`/location/${id}`, { parent_id: parent_id ?? 0 })
        .then()
        .catch(() => window.location.reload())
    },
    parentUpdateDisplay(ctx, { id, parent_id, sort }) {
      axios
        .put(`/displays/${id}`, { location_id: parent_id ?? null, sort })
        .then(this.dispatch('app-locations/fetchLocations'))
        .catch(() => window.location.reload())
    },
    parentUpdatePage(ctx, { id, parent_id, sort }) {
      parent_id = typeof parent_id === 'string' ? parent_id.replace('display', '') : parent_id
      axios
        .put(`/pages/${id}`, { display_id: parent_id, sort })
        .then()
        .catch(() => window.location.reload())
    },
    updateLocation(ctx, payload) {
      axios.put(`/location/${payload.id}`, { ...payload }).then(this.dispatch('app-locations/fetchLocations'))
    },
    updateDisplay(ctx, payload) {
      const { id } = payload
      const { path } = payload
      axios.put(`/displays/${id}`, { ...payload }).then(() => {
        let ref = ctx.state.tree
        for (const index in path) {
          const i = path[index]
          ref = ref[i]
          if (path.length === 1 || index == path.length - 2) {
            break
          } else {
            ref = ref.children
          }
        }
        if (path.length == 1) {
          this.dispatch('app-locations/fetchDisplays')
        } else {
          path.splice(-1)
          this.dispatch('app-locations/fetchDisplaysOfLocation', { id: ref.id, path, isUpdate: true })
        }
      })
    },
    deleteLocation(ctx, id) {
      axios.delete(`/location/${id}`).then(() => {
        this.dispatch('app-locations/fetchLocations')
      })
    },
    deleteDisplay(ctx, { id, path }) {
      axios.delete(`/displays/${id}`).then(() => {
        let ref = ctx.state.tree
        for (const index in path) {
          const i = path[index]
          ref = ref[i]
          if (path.length === 1 || index == path.length - 2) {
            break
          } else {
            ref = ref.children
          }
        }
        if (path.length === 1) {
          this.dispatch('app-locations/fetchDisplays')
        } else {
          path.splice(-1)
          this.dispatch('app-locations/fetchDisplaysOfLocation', { id: ref.id, path, isUpdate: true })
        }
      })
    },
    getDisplaysOfOpenLocation() {
      let allOpenLocations = {}
      try {
        const openItems = localStorage.getItem('location-list-open-locations')
        allOpenLocations = openItems ? JSON.parse(openItems) : {}
      } catch (e) {
        // no error here
      }
      Object.keys(allOpenLocations).forEach(id => {
        if (typeof allOpenLocations[id].fetchDisplays !== 'undefined') {
          if (allOpenLocations[id].fetchDisplays) {
            const { path } = allOpenLocations[id]
            this.dispatch('app-locations/fetchDisplaysOfLocation', { id, path })
          }
        } else {
          localStorage.removeItem('location-list-open-locations')
        }
      })
    },
  },
}
