<template>
  <div>
    <add-location
      :id="id"
      v-model="isLocationFormSidebarActive"
      :location-info="locationInfo"
      :is-edit-mode-location="isEditModeLocation"
      :type="type"
    />
    <v-card id="locations-list">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <h2>{{ $t('Locations') }}</h2>
        </div>
        <v-spacer />
        <div class="d-flex align-center pb-5">
          <v-btn
            v-if="$can('manage_system', 'System') || $can('manage_location', 'Location')"
            id="add-location-btn"
            color="primary"
            class="me-3"
            @click="addNewLocation"
          >
            <v-icon size="18" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>{{ $t('Add Location') }}</span>
          </v-btn>
          <v-btn
            v-if="$can('manage_system', 'System') || $can('manage_display', 'Display')"
            id="add-display-btn"
            color="primary"
            class="me-3"
            @click="addNewDisplay"
          >
            <v-icon size="18" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>{{ $t('Add Display') }}</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <Tree :indent="30" :value="tree" draggingNodePositionMode="mouse" @drop="onDrop" @drag="onDrag">
      <span
        slot-scope="{ node, path, tree }"
        class="editable-row"
        :class="[
          { level1: !(node.parent_id > 0) },
          node.type === 'display' ? 'display' : node.type === 'page' ? 'page' : 'location',
        ]"
      >
        <v-icon v-if="node.type === 'location'">
          {{ icons.mdiMapOutline }}
        </v-icon>
        <div v-if="node.type === 'display'" style="display: inline-flex">
          <v-icon style="padding-bottom: 10px">
            {{ icons.mdiMonitor }}
          </v-icon>
          <span>&nbsp;Anzeige</span>
        </div>
        <span v-if="node.type == 'location'"> {{ node.name }}</span>
        <div
          v-if="node.type == 'page'"
          :class="['superpage', node.status === 'active' ? '' : 'deactivated']"
          @click="goToPage(node)"
        >
          <div class="page-img">
            <img
              class="tree-node-image"
              style="object-fit: cover"
              :src="getPageImg(node)"
              width="155px"
              height="100px"
            />
          </div>
          <div class="page-content">
            <h4 class="page-title">
              <span>{{ node.name }}</span>
              <a
                v-if="$can('manage_system', 'System') || $can('manage_page', 'Page') || node.manage"
                style="z-index: 4; position: relative; width: 18px"
              >
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" size="18">
                      {{ icons.mdiDotsVertical }}
                    </v-icon>
                  </template>

                  <v-list>
                    <v-list-item @click="edit(node, path)">
                      <v-list-item-title>{{ $t('Edit') }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="openDeleteLocationModal(node, path)"
                      v-if="$can('manage_system', 'System') || $can('delete_page', 'Page')"
                    >
                      <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="statusChange(node, path)">
                      <v-list-item-title>{{
                        node.status === 'active' ? $t('Deactivate') : $t('Activate')
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="duplicate(node, path)">
                      <v-list-item-title>{{ $t('Duplicate') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </a>
            </h4>
            <v-icon size="14">{{ icons.mdiClockOutline }}</v-icon>
            <span style="font-size: 0.9em"> {{ getPageTime(node) }} </span>
          </div>
        </div>

        <v-icon
          size="24"
          v-if="
            ($can('manage_system', 'System') || $can('manage_location', 'Location') || node.manage) &&
            node.type === 'location'
          "
          class="edit-icon"
          @click="edit(node, path)"
        >
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon
          size="24"
          v-if="($can('manage_system', 'System') || $can('delete_location', 'Location')) && node.type === 'location'"
          class="edit-icon ml-2"
          @click="openDeleteLocationModal(node, path)"
        >
          {{ icons.mdiDelete }}
        </v-icon>

        <span
          v-if="node.type == 'location'"
          @click="tree.toggleFold(node, path)"
          :class="`${node.id + 'button'} float-right`"
        >
          {{ getDisplayLoactionNumber(node) }}
          <span v-if="locationHasMixedDisplayStatus(node.status, node.children)" class="pr-1 pl-1" style="top: 5px; position: relative;">
            <svg version="1.1" width="20" stroke="none" height="20" xmlns="http://www.w3.org/2000/svg">
                <clipPath id="cut-off">
                    <rect x="0" y="0" width="10" height="20"/>
                </clipPath>

                <circle cx="10" cy="10" r="10" fill="red"/>
                <circle cx="10" cy="10" r="10" fill="#56ca00" clip-path="url(#cut-off)"/>
            </svg>
          </span>
          <v-icon
            v-else
            class="pr-1 pl-1"
            :color="statusLocation(node.status, node.children) ? icon.Activecolor : icon.InActivecolor"
          >
            {{ icons.mdiCircle }}
          </v-icon>

          <v-icon @click="getDisplay(node, path)">{{
            node.$folded ? icons.mdiChevronUp : icons.mdiChevronDown
          }}</v-icon>
        </span>
        <br />

        <div v-if="node.type == 'display'" class="pages">
          <b style="z-index:1; position: relative;">
            <v-icon
              v-if="node.type === 'display'"
              class="pr-2 pt-2"
              style="z-index: 400"
              :color="node.status == 'active' ? icon.Activecolor : icon.InActivecolor"
            >
              {{ icons.mdiCircle }}
            </v-icon>
          </b>
          <div :class="['pageImage', node.mirror_id ? ' mirrored' : '']">
            <img
              class="tree-node-image"
              v-if="!screenshotColor(node.screenshot)"
              style="object-fit: cover; aspect-ratio: 16 / 9;"
              :src="getDisplayScrenshoot(node)"
              :width="280"
              :height="200"
            />
            <div
              v-if="screenshotColor(node.screenshot)"
              :style="{
                width: 420 + 'px',
                height: 420 / (16 / 9) + 'px',
                'background-color': node.screenshot,
                'border-radius': 10 + 'px',
              }"
            ></div>
            <div style="height: 180px; overflow-y: auto; overflow-x: hidden; width: 310px;">
              <h3 style="width: 280px; display: flex; justify-content: space-between">
                <span>{{ node.name }}</span>
                <a
                  v-if="$can('manage_system', 'System') || $can('manage_display', 'Display') || node.manage"
                  style="z-index: 4; position: relative; width: 18px"
                >
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon size="20" v-bind="attrs" v-on="on">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </template>

                    <v-list>
                      <v-list-item @click="edit(node, path)">
                        <v-list-item-title>{{ $t('Edit') }}</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        @click="openDeleteLocationModal(node, path)"
                        v-if="$can('manage_system', 'System') || $can('delete_display', 'Display')"
                      >
                        <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="statusChange(node, path)">
                        <v-list-item-title>{{
                          node.status === 'active' ? $t('Deactivate') : $t('Activate')
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="duplicate(node, path)">
                        <v-list-item-title>{{ $t('Duplicate') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="node.mirror_id" @click="unMirrore(node, path)">
                        <v-list-item-title>{{ $t('Unmirror') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item justify="left" v-if="!node.mirror_id">
                        <v-dialog v-model="mirrorDialog" scrollable max-width="300px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on"> {{ $t('Mirror') }} </v-list-item-title>
                          </template>
                          <v-card>
                            <v-card-title>{{ $t('Select Display to Mirror') }}</v-card-title>
                            <v-divider></v-divider>
                            <!-- <v-card-text style="height: 300px">
                              <v-radio-group v-model="mirrorId" column>
                                <v-radio v-for="dis in displayAll" :key="dis.id" :label="dis.name" :value="dis.id">
                                </v-radio>
                              </v-radio-group>
                            </v-card-text>-->
                            <v-treeview
                              :active.sync="active"
                              :items="location_hierarchy"
                              :search="search"
                              :open.sync="open"
                              activatable
                              color="success"
                              open-on-click
                              transition
                              item-children="childs"
                            >
                              <template #prepend="{ item }">
                                <v-icon v-if="item.type === 'display'">
                                  {{ icons.mdiMonitor }}
                                </v-icon>
                                <v-icon v-else>
                                  {{ icons.mdiFolderNetworkOutline }}
                                </v-icon>
                              </template>
                            </v-treeview>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-btn color="blue darken-1" text @click="mirrorDialog = false"> {{ $t('Close') }} </v-btn>
                              <v-btn color="blue darken-1" text @click="saveMirror(node, path)"> {{ $t('Save') }} </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item>
                      <v-list-item v-if="node.token" @click="disconnect(node)">
                        <v-list-item-title>{{ $t('Disconnects') }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </a>
              </h3>
              <p style="padding-right: 20px">{{node.description}}</p>
              <h4 v-if="node.mirror_id !== null" class="mirrorDisplayText">
                Diese Anzeige spiegelt Inhalte von {{ mirrorIdName(node.mirror_id) }}
              </h4>
              <h4
                v-if="$can('manage_page', 'Page') || $can('manage_system', 'System') || node.manage"
                @click="addNewPage(node.id, path)"
                style="cursor: pointer"
              >
                <v-icon size="18">{{ icons.mdiPlusCircleOutline }}</v-icon>
                {{ $t('Page') }}
              </h4>
            </div>
          </div>
        </div>
      </span>
    </Tree>
    <v-dialog v-model="deleteLocationModal" width="28%" height="auto" name="delete_location">
      <v-card>
        <v-card-title class="light">
          {{
            type === 'location'
              ? $t(`Are you sure you want to delete this location and all sublocations?`)
              : $t(`Are you sure you want to delete this ${type} ?`)
          }}
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeModal">
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn v-if="type === 'location'" color="primary" @click="deleteLocation()">
            {{ $t('Delete') }}
          </v-btn>
          <v-btn v-if="type === 'display'" color="primary" @click="deleteDisplay()">
            {{ $t('Delete') }}
          </v-btn>
          <v-btn v-if="type === 'page'" color="primary" @click="deletePage()">
            {{ $t('Delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbarShow" color="success" top>
      {{ $t(snackbarMessage) }}
      <template v-slot:action="{ attrs }">
        <v-btn color="default" text v-bind="attrs" @click="snackbarShow = false">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
import 'he-tree-vue/dist/he-tree-vue.css'
import { mapState } from 'vuex'
import { Tree, Draggable, Fold } from 'he-tree-vue'
import { onUnmounted, ref, onMounted } from '@vue/composition-api'

// import Vue from 'vue';

import {
  mdiDelete,
  mdiPencil,
  mdiPlus,
  mdiChevronDown,
  mdiChevronUp,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiMapOutline,
  mdiMonitor,
  mdiCircle,
  mdiClockOutline,
  mdiCircleHalf,
  mdiPlusCircleOutline,
  mdiFolderNetworkOutline,
} from '@mdi/js'
import store from '@/store'
import AddLocation from './AddLocation.vue'
import locationsStoreModule, { LOCATIONS_APP_STORE_MODULE_NAME } from '../locationsStoreModule'

// composition function
import useLocationList from './useLocationList'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  components: {
    AddLocation,
    Tree: Tree.mixPlugins([Draggable, Fold]),
  },
  data: () => ({
    active: [],
    mirrorDialog: false,
    windowWidth: window.innerWidth,
    type: '',
    showEditLocationButton: {},
    locationInfo: {},
    displayInfo: {},
    id: -1,
    isEditModeLocation: false,
    isEditModeDisplay: false,
    deleteLocationModal: false,
    selectLocationId: null,
    baseUrl: `${process.env.VUE_APP_BASE_URL_FILE}/storage`,
  }),
  watch: {
    showEditLocationButton: {
      deep: true,
      handler() {},
    },
    tree: {
      deep: true,
      handler() {
        setTimeout(() => {
          // document.querySelectorAll('span.page').forEach(el => {
          //   el.parentElement.parentElement.parentElement.parentElement.className += ' page-parent'
          //   el.parentElement.parentElement.parentElement.parentElement.parentElement.style.position = 'relative'
          // })
        }, 0)
      },
    },
  },
  methods: {
    getDisplayLoactionNumber(node) {
      const locationsNumber = node.children.filter(d => d.type == 'location').length
      if (locationsNumber === 0) {
        return this.$t('Display') + ': ' + node.displays_number
      }
      return locationsNumber + ' ' + this.$t('more locations')
    },
    unMirrore(node, path) {
      let { id } = node
      id = typeof id === 'string' ? id.replace('display', '') : id
      let payload = { id, path, mirror_id: null }
      store.dispatch('app-locations/updateDisplay', payload)
    },
    pageMirrored(id) {
      let result = false
      this.displayAll.forEach(display => {
        if (display.mirror_id) {
          if (display.id == id) {
            result = true
          }
        }
      })
      return result
    },
    mirrorIdName(id) {
      let result = ''
      this.displayAll.forEach(display => {
        if (display.id == id) {
          result = display.name
        }
      })
      return result
    },
    saveMirror(node, path) {
      this.mirrorDialog = false
      let { id } = node
      id = typeof id === 'string' ? id.replace('display', '') : id
      let payload = { id, path, mirror_id: this.selectedDisplayId }
      store.dispatch('app-locations/updateDisplay', payload)
    },
    statusChange(node, path) {
      let { id } = node
      id = typeof id === 'string' ? id.replace('display', '') : id
      let payload = { id, path, status: node.status == 'active' ? 'deactivated' : 'active' }
      if (node.type === 'display') {
        store.dispatch('app-locations/updateDisplay', payload)
      } else {
        store.dispatch('app-locations/updatePage', payload)
      }
    },
    duplicate(node, path) {
      if (node.type === 'display') {
        let payload = {
          name: 'Kopie von ' + node.name,
          location_id: node.location_id,
          status: node.status,
          mirror_id: node.mirror_id,
          pages: node.children,
          duplicate_id: node.id,
        }
        store.dispatch('app-locations/duplicateDisplay', payload)
      } else {
        path.splice(-1)
        let payload = { ...node, path }
        store.dispatch('app-locations/addPage', payload)
      }
    },
    disconnect(node) {
      if (node.type === 'display' && node.token) {
        store.dispatch('app-locations/disconnectDisplay', node.token, node.location_id)
      }
    },
    statusLocation(status, childrens) {
      if (status == 'active') {
        if (childrens.length > 0) {
          return !childrens.every(children => children.status === 'deactivated')
        } else {
          return true
        }
      } else {
        return false
      }
    },
    locationHasMixedDisplayStatus(status, childrens) {
      if (status == 'active') {
        if (childrens.length > 0) {
          if (!childrens.every(children => children.status === 'deactivated')) {
            return childrens.some(children => children.status === 'deactivated')
          }
          return false
        } else {
          return false
        }
      } else {
        return false
      }
    },
    screenshotColor(screenshot) {
      return JSON.stringify(screenshot).charAt(1) === '#'
    },
    goToPage(node) {
      window.open(`/pages/page/${node.id}`)
    },
    getDisplay(node, path) {
      const id = typeof node.id === 'string' ? node.id.replace('display', '') : node.id
      if (node.$folded) {
        store.dispatch('app-locations/fetchDisplaysOfLocation', { id, path })
      }
      this.nodeFoldChanged(node, path)
    },
    onDrag(e) {
      this.setChildrenDropable(this.tree, e.dragNode.type, e)
    },
    setChildrenDropable(children, type, tree) {
      if (children.length > 0) {
        //  tree.droppable = true
        children.forEach(a => {
          if (a.type === 'location') {
            if (type === 'page') {
              a.$droppable = false
            } else {
              a.$droppable = true
            }
            this.setChildrenDropable(a.children, type, tree)
          }
          if (a.type === 'display') {
            if (type === 'page') {
              a.$droppable = true
            } else {
              a.$droppable = false
            }
            this.setChildrenDropable(a.children, type, tree)
          }
        })
        // } else if (type === 'page' && tree) {
        //   tree.droppable = false
        // } else if(tree) {
        //   tree.droppable = true
      }
    },
    onDrop(e) {
      const node = e.dragNode
      let ref = this.tree
      for (const index in e.targetPath) {
        const i = e.targetPath[index]
        ref = ref[i]
        if (index == e.targetPath.length - 2) {
          break
        } else {
          ref = ref.children
        }
      }
      if (node.type === 'page') {
        if (ref.mirror_id) {
          node.$nodeClass = 'mirrored page-branch'
        } else {
          node.$nodeClass = 'page-branch'
        }
      }
      const id = typeof node.id === 'string' ? node.id.replace('display', '') : node.id
      const locationParent = { id: id, parent_id: ref?.id ?? null, sort: e.targetPath[e.targetPath.length - 1] }
      node.parent_id = ref.id

      if (node.type === 'display') {
        store.dispatch('app-locations/parentUpdateDisplay', locationParent)
      } else if (node.type === 'page') {
        store.dispatch('app-locations/parentUpdatePage', locationParent)
      } else {
        store.dispatch('app-locations/parentUpdateLocation', locationParent)
      }
    },
    edit(e, path) {
      this.isEditModeLocation = true
      this.type = e.type
      this.isLocationFormSidebarActive = true
      const id = typeof e.id === 'string' ? e.id.replace('display', '') : e.id
      this.locationInfo = {
        id,
        name: e.name,
        status: e.status,
        groups: e.groups,
        path,
      }
      if (this.type === 'display') {
        this.locationInfo.description = e.description
      }
      this.id = e.id
    },
    addNewLocation() {
      this.isEditModeLocation = false
      this.type = 'location'
      this.locationInfo = { name: '', status: 'active', groups: [] }
      this.isLocationFormSidebarActive = !this.isLocationFormSidebarActive
    },
    openDeleteLocationModal(node, path) {
      this.deleteLocationModal = true
      this.selectedNodeForDelete = node
      this.type = node.type
      this.selectedPathForDelete = path
    },
    closeModal() {
      this.deleteLocationModal = false
    },
    deleteLocation() {
      const { id } = this.selectedNodeForDelete
      store.dispatch('app-locations/deleteLocation', id)
      this.closeModal()
    },
    deleteDisplay() {
      let { id } = this.selectedNodeForDelete
      id = typeof id === 'string' ? id.replace('display', '') : id
      const payload = { id, path: this.selectedPathForDelete }
      store.dispatch('app-locations/deleteDisplay', payload)
      this.closeModal()
    },
    deletePage() {
      const { id } = this.selectedNodeForDelete
      const payload = { id, path: this.selectedPathForDelete }
      store.dispatch('app-locations/deletePage', payload)
      this.closeModal()
    },
    editDisplay(e) {
      this.isEditModeDisplay = true
      this.isLocationFormSidebarActive = true
      this.locationInfo = {
        id: e.id,
        name: e.name,
        status: e.status,
        groups: e.groups,
      }
      this.id = e.id
    },
    addNewDisplay() {
      this.isEditModeLocation = false
      this.type = 'display'
      this.locationInfo = { name: '', status: 'active', groups: [] }
      this.isLocationFormSidebarActive = !this.isLocationFormSidebarActive
    },
    addNewPage(id, path) {
      this.isEditModeLocation = false
      this.type = 'page'
      id = typeof id === 'string' ? id.replace('display', '') : id
      this.locationInfo = {
        name: '',
        status: 'active',
        groups: [],
        display_id: id,
        path,
      }
      this.isLocationFormSidebarActive = !this.isLocationFormSidebarActive
    },
    nodeFoldChanged(node, path) {
      let allOpenLocations = {}
      try {
        const openItems = localStorage.getItem('location-list-open-locations')
        allOpenLocations = openItems ? JSON.parse(openItems) : {}
      } catch (e) {
        // no error here
      }
      if (typeof allOpenLocations !== 'object') {
        allOpenLocations = {}
      }
      if (node.$folded) {
        allOpenLocations[node.id] = {
          path,
          fetchDisplays: node.displays_number && node.displays_number > 0,
        }
      } else if (allOpenLocations[node.id]) {
        delete allOpenLocations[node.id]
      }
      localStorage.setItem('location-list-open-locations', JSON.stringify(allOpenLocations))
    },
    getPageImg(node) {
      if (node.screenshot) {
        return process.env.VUE_APP_BASE_URL_FILE + node.screenshot
      }
      return 'https://www.opexroundrock.com/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png'
    },
    getDisplayScrenshoot(node) {
      if (node.screenshot) {
        return this.baseUrl + node.screenshot
      }
      return 'https://www.opexroundrock.com/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png'
    },
    getPageTime(node) {
      if (
        node.visibility === 'absolut' &&
        node.absolut_config &&
        Array.isArray(node.absolut_config) &&
        node.absolut_config.filter(c => c.startTime || c.endTime).length
      ) {
        const conf = node.absolut_config.filter(c => c.startTime || c.endTime).pop()
        return (conf.startTime ? conf.startTime.slice(0, 5) : '') + '-' + (conf.endTime ? conf.endTime.slice(0, 5) : '')
      } else if (node.visibility === 'relativ' && node.relativ) {
        return node.relativ + ' sec'
      } else {
        return this.$t('No time set')
      }
    },
  },
  computed: {
    ...mapState('app', ['location_hierarchy']),
    selectedDisplayId() {
      if (this.active.length > 0) {
        return this.active[0].replace('display-', '')
      }

      return null
    },
  },
  mounted() {
    store.dispatch('app/fetchLocationHierarchyByDisplay')
  },
  setup() {
    const open = ref([])
    const search = ref(null)
    const { message } = router.currentRoute.params
    const snackbarShow = ref(!!message)
    const snackbarMessage = ref(message)
    const snackbarColor = ref(null)
    if (!store.hasModule(LOCATIONS_APP_STORE_MODULE_NAME)) {
      store.registerModule(LOCATIONS_APP_STORE_MODULE_NAME, locationsStoreModule)
    }
    const { treeId } = router.currentRoute.params

    onMounted(() => {
      if (window.location.href.indexOf('/locations/list/add') > -1) {
        setTimeout(() => {
          document.getElementById('add-location-btn').click()
        }, 1000)
      }
      const theme = localStorage.getItem('bue-active-theme')
      if (theme === 'dark') {
        document.head.insertAdjacentHTML(
          'beforeend',
          `<style>
            .he-tree .tree-node {
            background-color: #312d4b !important;
            }
            .pages {
              background: #312d4b;
              background: linear-gradient(90deg, #312d5b 325px, #28243d 325px, #28243d 100%);
            }
            .tree-node.page-branch {
              background-color: #312d4b !important;
            }
          </style>`,
        )
        return
      }
      document.head.insertAdjacentHTML(
        'beforeend',
        `<style>
            .he-tree .tree-node {
            background-color: white !important;
            }
            .pages {
              background: rgb(241,241,241);
              background: linear-gradient(90deg, rgba(241,241,241,1) 325px, rgba(228,228,228,1) 325px, rgba(228,228,228,1) 100%); 
            }
            .tree-node.page-branch {
              background-color: rgba(228,228,228,1) !important;
            }
          </style>`,
      )
      // setTimeout(() => {
      //   store.dispatch('app-locations/getDisplaysOfOpenLocation')
      // }, 1000)
      const treeElement = document.getElementsByClassName(`${treeId}`)
      if (treeId && treeElement.length) {
        treeElement[0].scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        })
      }
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LOCATIONS_APP_STORE_MODULE_NAME)) store.unregisterModule(LOCATIONS_APP_STORE_MODULE_NAME)
    })

    const { locations, display, tree, displayAll } = useLocationList()
    const isLocationFormSidebarActive = ref(false)
    const isDisplayFormSidebarActive = ref(false)

    return {
      show: false,
      tree,
      locations,
      display,
      displayAll,
      isLocationFormSidebarActive,
      isDisplayFormSidebarActive,
      icons: {
        mdiDelete,
        mdiPencil,
        mdiPlus,
        mdiChevronDown,
        mdiChevronUp,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiMapOutline,
        mdiMonitor,
        mdiCircle,
        mdiClockOutline,
        mdiCircleHalf,
        mdiPlusCircleOutline,
        mdiFolderNetworkOutline,
      },
      icon: {
        Activecolor: 'success',
        InActivecolor: 'error',
      },
      snackbarShow,
      snackbarMessage,
      snackbarColor,
      open,
      search,
    }
  },
}
</script>

<style lang="scss">
.superpage {
  display: inline-flex;
  width: 100%;
  &.deactivated {
    opacity: 0.5;
  }
  .page-img {
    width: 175px;
  }
  .page-content {
    width: calc(100% - 175px);
    margin-top: 20px;
    .page-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
b,
strong {
  float: right;
}
.he-tree {
  margin-top: 15px;
  .tree-node-back {
    min-height: 72px;
    margin-bottom: 4px;
  }
}
.he-tree .tree-node {
  border: none !important;
  background-color: white !important;
  min-height: 72px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 0 25px;
  margin: 0;
  border-bottom-right-radius: 0;
  .editable-row {
    width: 100%;
  }
}
.tree-branch.location-branch > .tree-children.has-displays > .tree-branch.display-branch {
  .tree-node {
    margin-top: -4px;
    border-radius: 0px;
    padding-top: 13px;
    padding-bottom: 7px;
  }
  &:last-child {
    .tree-node {
    padding-bottom: 25px;
    }
  }
}
.tree-children.has-displays .tree-branch {
    margin: 8px 0;
}
.tree-branch .tree-children {
  padding-left: 30px;
}
.he-tree .tree-node:hover .editable-row .edit-icon {
  display: initial;
}
.editable-row .edit-icon {
  margin-left: 8px;
  display: none;
}
.display {
  display: block;
}
.pages {
  border-radius: 5px;
  flex-basis: 100%;
  margin-top: 6px;
  margin-left: -4px;
}
.pageImage {
  padding: 15px 20px 20px 15px;
  height: 400px;
  width: 325px;
}
.tree-children.page-parent {
  position: absolute;
  left: 440px;
  height: 360px;
  top: 61px;
  width: calc(100% - 470px);
  overflow-y: auto;
  padding: 0;
  margin-top: 30px;
  .tree-branch {
    padding-left: 0 !important;
    .tree-node-back {
      padding-left: 0 !important;
    }
    .page-branch:hover {
      box-shadow: 1px 2px 1px 1px rgb(0 0 0 / 5%);
      background-color: #cbc8c8 !important;
      border-radius: 5px !important;
      /* padding-bottom: 5px; */
      cursor: pointer;
    }
  }
}
.tree-branch {
  position: relative;
}
.tree-node.page-branch {
  border-radius: 10px;
  box-shadow: none;
}

.tree-node-image {
  border-radius: 5px;
  border-style: solid;
  border-color: #000;
  border-width: 3px;
}
.pulse {
  animation: pulse-white 2s normal;
}

@keyframes pulse-white {
  0% {
    transform: scale(1.02);
    box-shadow: 0px 0px 20px 0px #0dff00;
  }
}
.mirrored {
  opacity: 0.4;
}
.mirrorDisplayText {
  background-color: #f75b00;
  padding: 5px;
  width: 280px;
  color: #fff;
}
/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #a0a0a0;
}
.display-branch .tree-children .tree-branch {
  padding-left: 30px;
}
.tree-children.tree-root > .tree-branch {
  margin-bottom: 15px;
}
.tree-children.tree-root > .tree-branch.display-branch .tree-node {
  padding-top: 25px;
  padding-bottom: 25px;
}
@keyframes spinner {
  to {transform: rotate(360deg);}
}
 
.tree-branch.location-branch.loading:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
}
</style>