import store from '@/store'
import { computed } from '@vue/composition-api'

export default function useLocationList() {
  const locations = computed(() => store.getters['app-locations/locations'])
  const display = computed(() => store.getters['app-locations/display'])
  const displayAll = computed(() => store.getters['app-locations/displayAll'])
  const tree = computed(() => store.getters['app-locations/tree'])

  store.dispatch('app-locations/fetchLocations')
  store.dispatch('app-locations/fetchGroups')
  const groupOptions = computed(() => store.getters['app-locations/groups'].map(el => ({
    id: el.id,
    title: el.name,
    value: el.id,
  })))

  return {
    tree,
    displayAll,
    locations,
    display,
    groupOptions,
  }
}
