<template>
  <div>
    <v-navigation-drawer
      :value="isLocationFormSidebarActive"
      temporary
      touchless
      :right="!$vuetify.rtl"
      width="350"
      app
      @input="(val) => $emit('update:is-add-payment-sidebar-active', val)"
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center mb-4">
          <h4 class="font-weight-semibold text-base">
            {{ (type === 'location') ? $t('Locations') : '' }}
            {{ (type === 'display') ? $t('Display') : '' }}
            {{ (type === 'page') ? $t('Page') : '' }}
          </h4>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-add-payment-sidebar-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>
        <v-card-text class="pt-5">
          <v-form
            ref="form"
            @submit.prevent="onSubmit"
          >
            <v-text-field
              v-model="locationInfo.name"
              outlined
              :label="$t('Title of the '+type)"
              dense
              hide-details="auto"
              class="mb-6"
              required
            />
            <v-text-field
              v-if="(type === 'display')"
              v-model="locationInfo.description"
              outlined
              :label="$t('Description')"
              dense
              hide-details="auto"
              class="mb-6"
            />
            <v-select
              v-model="locationInfo.status"
              :items="statuses"
              item-text="label"
              item-value="value"
              outlined
              dense
              single-line
              label="Status"
            ></v-select>
            <v-select
              v-if="(type !== 'page')"
              v-model="locationInfo.groups"
              :label="$t('Groups')"
              :items="groupOptions"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details="auto"
              class="mb-6"
              multiple
            />
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              @click="onSubmit(isEditModeLocation,locationInfo,type)"
            >
              {{ isEditModeLocation ? $t('Update'): $t('Save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              type="reset"
              @click="resetData"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbarShow"
      :color="snackbarColor"
      top
    >
      {{ $t(snackbarMessage) }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbarShow = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
/* eslint-disable vue/require-prop-type-constructor */
import { mdiClose } from '@mdi/js'
import store from '@/store'
import { i18n } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'
import useLocationList from './useLocationList'

export default {
  model: {
    prop: 'isLocationFormSidebarActive',
    event: 'update:is-add-payment-sidebar-active',
  },
  props: {
    isLocationFormSidebarActive: {
      type: Boolean,
      required: true,
    },
    locationInfo: {
      type: Object,
      required: true,
    },
    id: {
      type: String | Number,
      required: true,
    },
    isEditModeLocation: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(emit) {
    const snackbarShow = ref(false)
    const snackbarMessage = ref(null)
    const snackbarColor = ref(null)
    const blankLocationsData = {
      name: '',
      status: '',
      groups: null,
    }
    const form = ref(null)
    const {
      groupOptions,
    } = useLocationList()

    const resetForm = () => {
      form.value.reset()
    }
    const locationsData = ref(JSON.parse(JSON.stringify(blankLocationsData)))
    const resetData = () => {
      locationsData.value = JSON.parse(JSON.stringify(blankLocationsData))
      resetForm()
      emit.isLocationFormSidebarActive = false
    }
    const statuses = [
      {
        value: 'active',
        label: i18n.tc('Active'),
      },
      {
        value: 'deactivated',
        label: i18n.tc('Deactivated'),
      }]
    const onSubmit = (isUpdate, locationInfo, type) => {
      if (isUpdate) {
        if (type === 'location') {
          store.dispatch('app-locations/updateLocation', locationInfo)
        } else if (type === 'display') {
          store.dispatch('app-locations/updateDisplay', locationInfo)
        } else if (type === 'page') {
          store.dispatch('app-locations/updatePage', locationInfo)
        }
        resetData()
      } else {
        if (type === 'location') {
          store.dispatch('app-locations/addLocation', locationInfo)
        } else if (type === 'display') {
          store.dispatch('app-locations/addDisplay', locationInfo)
        } else if (type === 'page') {
          store.dispatch('app-locations/addPage', locationInfo)
        }
        resetData()
      }
      emit.isLocationFormSidebarActive = false
    }

    return {
      statuses,
      icons: {
        mdiClose,
      },
      groupOptions,
      resetData,
      form,
      locationsData,
      onSubmit,
      snackbarShow,
      snackbarMessage,
      snackbarColor,
    }
  },
}
</script>
